<template>
  <div class="annual"
       v-loading="loading"
       element-loading-text="抽奖中"
       element-loading-background="rgba(0,0,0,.7)">
    <div class="cover"
         v-if="loginVisible || luckyVisible">
    </div>
    <div class="qrcode">
      <img :src="prizeList[prizeIndex] && (baseUrl + prizeList[prizeIndex].qrCodeUrl)"
           alt="">
    </div>
    <div class="participant">
      <div class="num">
        <span>当前参与数：{{prizeList[prizeIndex] && prizeList[prizeIndex].joinNum}}人/次</span>
      </div>
      <div class="prize">
        <div class="arrow left-arrow"
             @click="clickLeft">
          <img src="./image/left-arrow.png"
               alt="">
        </div>
        <div class="prize-name">
          <span>{{prizeList[prizeIndex] && prizeList[prizeIndex].name}}</span>
        </div>
        <div class="arrow right-arrow"
             @click="clickRight">
          <img src="./image/right-arrow.png"
               alt="">
        </div>
      </div>
    </div>
    <div class="draw-btn"
         @click='draw'
         v-if="!endOfDraw[prizeIndex]">
      <img src="./image/draw.png"
           alt="">
    </div>
    <div class="draw-btn-bg">
      <img src="./image/draw-bg.png"
           alt="">
    </div>
    <div class="draw-btn"
         v-if="endOfDraw[prizeIndex]">
      <img src="./image/end.png"
           alt="">
    </div>
    <!-- 密码登录模块 -->
    <div class="login"
         v-if="loginVisible">
      <el-input v-model="password"
                type="password"
                maxlength="4"
                placeholder="请输入登录密码"></el-input>
      <div class="submit"
           @click="checkPassword">
        <img src="./image/submit.png"
             alt="">
      </div>
      <div class="submit-bg">
        <img src="./image/submit-bg.png"
             alt="">
      </div>
    </div>

    <!-- 中奖公示模块 -->
    <div class="lucky"
         v-if="luckyVisible">
      <div class="info">
        <div class="avatar">
          <img :src="lucky.avatarUrl"
               alt="">
        </div>
        <div class="detail">
          <div class="name">{{lucky.nickName}}</div>
          <div class="phone">{{lucky.phone}}</div>
        </div>
      </div>
      <div class="submit"
           @click="closeLucky">
        <img src="./image/submit.png"
             alt="">
      </div>
      <div class="submit-bg">
        <img src="./image/submit-bg.png"
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { _local } from '@/utils/localStorage.js'
export default {
  data() {
    return {
      loginVisible: true,
      luckyVisible: false,
      drawEnd: true,
      password: '',
      prizeList: [],
      prizeIndex: 1,
      lucky: {},
      endOfDraw: [false, false, false, false, false, false],
      loading: false,
      baseUrl: ''
    }
  },
  mounted() {
    this.getList()
    if (_local.get('loginDraw')) {
      this.loginVisible = false
    }
    this.password = ''
    this.baseUrl = window.BASE_URL
  },
  methods: {
    checkPassword() {
      if (this.password === 'aa11') {
        this.loginVisible = false
        _local.set('loginDraw', 'true')
        this.$message({
          type: 'success',
          message: '登录成功，激动人心的时刻到了~'
        })
      } else {
        this.$message({
          type: 'error',
          message: '密码错误，请重新输入'
        })
      }
      // this.$http.annual.loginDraw(this.password).then(res => {
      //   if (res.data.code === 0) {
      //     this.loginVisible = false
      //     _local.set('loginDraw', 'true')
      //     this.$message({
      //       type: 'success',
      //       message: res.data.message
      //     })
      //   } else {
      //     this.$message({
      //       type: 'error',
      //       message: res.data.message
      //     })
      //   }
      // })
    },
    getList() {
      this.$http.annual.getDrawList().then(
        res => {
          if (res.data.code === 0) {
            this.prizeList = res.data.data
            this.checkEndofDraw()

            console.log(this.prizeList)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        }
      )
    },
    clickLeft() {
      if (this.prizeCheck() === 'min') {
        this.prizeIndex = 5
      } else {
        this.prizeIndex--
      }
      console.log(this.prizeList[this.prizeIndex].name + ':' + _local.get('prize' + this.prizeIndex))
      this.checkEndofDraw()
    },
    clickRight() {
      if (this.prizeCheck() === 'max') {
        this.prizeIndex = 1
      } else {
        this.prizeIndex++
      }
      console.log(this.prizeList[this.prizeIndex].name + ':' + _local.get('prize' + this.prizeIndex))
      this.checkEndofDraw()
    },
    prizeCheck() {
      if (this.prizeIndex === 1) {
        return 'min'
      } else if (this.prizeIndex === 5) {
        return 'max'
      }
      return null
    },
    draw() {
      this.$http.annual.draw({
        userId: this.prizeList[this.prizeIndex].userId,
        ticketId: this.prizeList[this.prizeIndex].ticketId
      }).then(res => {
        if (res.data.code === 0) {
          this.loading = true
          this.handleDrawCount()
          this.lucky = res.data.data
          this.lucky.phone = this.lucky.phone.slice(0, 3) + ' ' + this.lucky.phone.slice(3, 7) + ' ' + this.lucky.phone.slice(7)
          setTimeout(() => {
            this.loading = false
            this.luckyVisible = true
          }, 1000)
          // } else if (res.data.code === 500) {
          //   _local.set('prize' + this.prizeIndex, this.prizeList[this.prizeIndex].num)
          //   this.$set(this.endOfDraw, this.prizeIndex, true)
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
          if (res.data.code === 50502) {
            _local.set('prize' + this.prizeIndex, this.prizeList[this.prizeIndex].num)
            this.$set(this.endOfDraw, this.prizeIndex, true)
          }
        }
      })
    },
    loadNum() {
      this.num = ''
    },
    closeLucky() {
      this.luckyVisible = false
    },
    handleDrawCount() {
      let index = _local.get('prize' + this.prizeIndex)
      if (!index) {
        _local.set('prize' + this.prizeIndex, 1)
      } else {
        _local.set('prize' + this.prizeIndex, index + 1)
      }
      this.checkEndofDraw()
      console.log(this.prizeList[this.prizeIndex].name + ':' + _local.get('prize' + this.prizeIndex))
    },
    checkEndofDraw() {
      let index = _local.get('prize' + this.prizeIndex)
      if (index >= this.prizeList[this.prizeIndex].num) {
        this.$set(this.endOfDraw, this.prizeIndex, true)
      }
    }
  }
}
</script>

<style lang="scss">
.annual {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("./image/annual-bg.png");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  .qrcode {
    position: absolute;
    right: 4vw;
    bottom: 4vw;
    z-index: 98;
    img {
      width: 10vw;
      height: 10vw;
      transform-origin: bottom right;
      transition: all 0.5s ease 0s;
    }
    &:hover {
      img {
        transform: scale(2.5);
      }
    }
  }
  .cover {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }
  .login {
    position: absolute;
    // width: 33.8vw;
    width: 28.17vw;
    height: 33.025vw;
    // height: 39.63vw;
    top: 8%;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("./image/login.png");
    background-size: cover;
    background-position: center;
    z-index: 100;
    .el-input {
      position: absolute;
      // top: 19.06vw;
      top: 15.88vw;
      left: 50%;
      transform: translateX(-50%);
      // width: 15.624vw;
      width: 13.02vw;
      outline: none;
      &__inner {
        // height: 5.104vw;
        height: 4.253vw;
        border-radius: 49px;
        // padding: 0 1.98vw;
        padding: 0 1.65vw;
        // font-size: 3.75vw;
        font-size: 3.125vw;
        font-weight: 900;
        text-align: center;
        color: #dc2627;
        background-color: #f9efe9;
        &::-webkit-input-placeholder {
          /* WebKit browsers */
          font-size: 1.1vw;
          color: #b29f9f;
          transform: translate(0, 11%);
          -webkit-transform: translate(0, -50%);
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          font-size: 1.1vw;
          color: #b29f9f;
          transform: translate(0, 11%);
          -webkit-transform: translate(0, -50%);
        }
        &::-ms-input-placeholder {
          /* Internet Explorer 10+ */
          font-size: 1.1vw;
          color: #b29f9f;
          transform: translate(0, 11%);
          -webkit-transform: translate(0, -50%);
        }
        &:focus {
          outline: none;
          border-color: #f9efe9;
        }
      }
    }
    .submit {
      position: absolute;
      height: 5vw;
      top: 26.8vw;
      // top: 31.72vw;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      cursor: pointer;
      img {
        // width: 13.325vw;
        // height: 7.075vw;
        width: 8.66vw;
        height: 3.423vw;
      }
    }
    .submit-bg {
      position: absolute;
      bottom: 1vw;
      left: 50%;
      transform: translateX(-45%);
      img {
        width: 17.856vw;
        height: 8.413vw;
      }
    }
  }
  .lucky {
    position: absolute;
    // width: 33.8vw;
    // height: 39.63vw;
    width: 28.17vw;
    height: 33.025vw;
    // top: 10%;
    top: 8%;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("./image/lucky.png");
    background-size: cover;
    background-position: center;
    z-index: 100;
    .info {
      position: absolute;
      top: 43%;
      left: 50%;
      transform: translateX(-50%);
      .avatar {
        // width: 6.67vw;
        // height: 6.67vw;
        width: 5.57vw;
        height: 5.57vw;
        border: 2px solid #d4722a;
        border-radius: 50%;
        margin: auto auto;
        overflow: hidden;
        img {
          // width: 6.67vw;
          // height: 6.67vw;
          width: 5.57vw;
          height: 5.57vw;
        }
      }
      .detail {
        margin-top: 20px;
        text-align: center;
        color: #9e5611;
        .name {
          // font-size: 1.46vw;
          font-size: 1.22vw;
          font-weight: 600;
        }
        .phone {
          // font-size: 1.25vw;
          font-size: 1.042vw;
          margin-top: 8px;
          font-weight: 500;
        }
      }
    }
    .submit {
      position: absolute;
      height: 5vw;
      top: 26.8vw;
      // top: 31.72vw;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      cursor: pointer;
      img {
        // width: 13.325vw;
        // height: 7.075vw;
        width: 8.66vw;
        height: 3.423vw;
      }
    }
    .submit-bg {
      position: absolute;
      bottom: 1vw;
      left: 50%;
      transform: translateX(-45%);
      img {
        width: 17.856vw;
        height: 8.413vw;
      }
    }
  }
  .draw-btn {
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    cursor: pointer;
    img {
      // width: 11.633vw;
      // height: 4.606vw;
      // width: 17.324vw;
      // height: 6.86vw;
    }
  }
  .draw-btn-bg {
    position: absolute;
    bottom: -0.6%;
    left: 50%;
    transform: translateX(-43%);
    opacity: 0.6;
  }
  .participant {
    position: absolute;
    top: 32vh;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fce7d9;
    .num {
      font-size: 24px;
      cursor: default;
    }
    .prize {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 30vw;
      height: 10vw;
      margin-top: 10.189vh;
      .prize-name {
        width: 20vw;
        font-size: 36px;
        font-weight: 500;
        cursor: default;
      }
      .arrow {
        width: 22px;
        height: 38px;
        cursor: pointer;
        img {
          width: 22px;
          height: 38px;
        }
      }
    }
  }
}
</style>
